
export default function Off() {


  return (
    <div>

    The whiteboard is currently under maintenance.
    <div>
      Thank you for your patience and your understanding.
    </div>

    </div>
  )


}




