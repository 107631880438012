import { Tldraw } from '@tldraw/tldraw'
import '@tldraw/tldraw/editor.css'
import '@tldraw/tldraw/ui.css'
import { useCallback, useEffect, useState } from 'react';
import { persistenceKeyCustom, persistenceKeyTLDraw, MSG_TOKENID, autosaveInterval } from './config'
import { prepareLocalStorage, initDB, getStoreData } from './utils/manageDB'
import { uiOverrides } from './ui-overrides'
import { compareJSON, isMediaAccepted } from './utils';
import ReactModal from 'react-modal';
ReactModal.setAppElement('#root');

let idRemoved = [];
let prevData = [];

export default function App() {

  const [isDBReady, setIsDBReady] = useState(false);
  const [eventSourceMain, setEventSourceMain] = useState(null);
  const [msgFromOpair, setMsgFromOpair] = useState(null);
  //const [prevData, setPrevData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  //const [idRemoved, setIdRemoved] = useState([]);



  useEffect(() => {

    if (msgFromOpair === null) {
      return;
    }

    prepareLocalStorage();

    //----- delete existing database instance
    const deleteRequest = window.indexedDB.deleteDatabase(persistenceKeyCustom);

    deleteRequest.onerror = (event) => {
      console.error("Error deleting database.");
    };

    deleteRequest.onsuccess = (event) => {
      console.log("Database deleted successfully");

      console.log('onsuccess', { result: event.result }); // should be undefined

      // create database + stores + insert data
      initDB(msgFromOpair);

      // TLDraw can be displayed
      setIsDBReady(true);
    };

  }, [msgFromOpair]);



  useEffect(() => {
    window.addEventListener("message", (event) => {

      const data = event.data;
      if (data == null || typeof data !== 'object' || (typeof data === 'object' && data.msgid !== MSG_TOKENID)) {
        return;
      }
      console.log('useefect - message from opair', { event });

      setMsgFromOpair(event.data);
      //setPrevData(event.data.baseData);
      prevData = event.data.baseData;
      setEventSourceMain({ source: event.source, origin: event.origin });

    });
  }, []);

  // useEffect(() => {
  //   console.log("useEffect - prevData", { prevData });
  // }, [prevData]);



  const excludeDataToSend = ['camera', 'instance', 'instance_page_state', 'user_presence'];
  const sendDataToOpair = (data) => {
    // console.log("sendDataToOpair - data", { data });
    // console.log("sendDataToOpair - prevData", { prevData });


    const filtered = data.filter(d => !excludeDataToSend.includes(d.typeName));

    const dataToDismiss = [];
    const idsToRemove = [];
    // console.log("sendDataToOpair - filtered", { filtered });

    // compare if new data as been deleted
    prevData.forEach(prevItem => {
      const nextItem = filtered.find(d => d.id === prevItem.id);
      if (nextItem == null) {
        // data be removed
        idsToRemove.push(prevItem.id)

        // if prevItemData is shape image        
        if (prevItem.typeName === 'shape' && prevItem.type === 'image') {
          // flag asset to be removed
          idsToRemove.push(prevItem.props.assetId)
        }
      } else {
        // compare if any change occured in json content
        const areIdentical = compareJSON(prevItem, nextItem);
        // console.log("comparing ... ", { prevItem, nextItem, areIdentical });
        //push id dans dataToDismiss
        if (areIdentical) { dataToDismiss.push(prevItem.id) };
      }
    })

    const cleanedIdToRemove = idsToRemove.filter(id => !idRemoved.includes(id))
    //console.log("ids to remove", {idsToRemove, idRemoved, cleanedIdToRemove})
    idRemoved = [...idRemoved, ...cleanedIdToRemove];
    // console.log("dataToDismiss", dataToDismiss);

    const cleanedData = filtered.filter(d => !cleanedIdToRemove.includes(d.id) && !dataToDismiss.includes(d.id));
    console.log("cleanedData", cleanedData);

    const filteredPrevData = prevData.filter(obj1 => !cleanedData.find(obj2 => obj2.id === obj1.id));
    //setPrevData([...filteredPrevData, ...cleanedData]);
    prevData = [...filteredPrevData, ...cleanedData]
    //console.log("newPrevData ", prevData);

    /* const dataToSendBack =  {
      data: cleanedData,
      cleanedIdToRemove,
      msgid: MSG_TOKENID
    }
    console.log("sendDataToOpair - cleanedData", {dataToSendBack}); */
    //return; // !!!!! enlever apres test
    console.log("cleanedData & cleanedIdToRemove", {cleanedData, cleanedIdToRemove});
    if (cleanedData.length > 0 || cleanedIdToRemove.length > 0) {
      eventSourceMain.source.postMessage(
        {
          data: cleanedData,
          idsToRemove: cleanedIdToRemove,
          msgid: MSG_TOKENID
        },
        eventSourceMain.origin
      );
    }
  }

  const triggerMessage = () => {
    // console.log("eventMain", { eventSourceMain });
    getStoreData(sendDataToOpair);
  }

  /* ----------------------------------------------- */
  function handleEvent(name, data) {
    // do something with the event
    console.log('onUiEvent', { name, data })
  }

  /* ----------------------------------------------- */
  const [editor, setEditor] = useState();
  /* const [storeEvents, setStoreEvents] = useState([]) */

  const setAppToState = useCallback((editor) => {
    console.log("setAppToState editor");
    setEditor(editor)
  }, [])




  useEffect(() => {
    if (!editor) return
    console.log("useEffect editor");

    /* function logChangeEvent(eventName) {
      setStoreEvents((events) => [eventName, ...events])
    } */

    // This is the fire hose, it will be called at the end of every transaction
    const handleChangeEvent = (change) => {

      if (change.source === 'user') {

        // Added
        let deletedmediaIds = [];
        let mediaHasBeenDeleted = false;
        for (const record of Object.values(change.changes.added)) {
          if (record.typeName === 'asset' && record.type === 'image') {
            const isOk = isMediaAccepted(record.props.src);
            console.log('handleChangeEvent - Image accepted', { isOk });
            if (!isOk) {
              console.log(`deleting asset... ${record.id}`);
              editor.deleteAssets([record.id]);
              deletedmediaIds = [record.id];
              mediaHasBeenDeleted = true;
            }
          }

          if (record.typeName === 'shape' && record.type === 'image') {
            console.log('handleChangeEvent - shape for image', { record, deletedmediaIds });
            if (deletedmediaIds.includes(record.props.assetId)) {
              console.log(`deleting shape ${record.id} associated to asset to ${record.props.assetId}`);
              editor.deleteShapes([record.id]);
              mediaHasBeenDeleted = true;
            }
          }
        }
        if (mediaHasBeenDeleted) {
          setOpenModal(true);
          //alert("Les images et medias ne doivent pas dépasser 500k");
        }

        // Updated

        for (const [from, to] of Object.values(change.changes.updated)) {

          if (from.typeName === 'user_presence') return;
          console.log('handleChangeEvent - Updated', { from, to });





          /* if (
            from.typeName === 'instance' &&
            to.typeName === 'instance' &&
            from.currentPageId !== to.currentPageId
          ) {
            logChangeEvent(`changed page (${from.currentPageId}, ${to.currentPageId})`)
          } */


        }

        // Removed
        for (const record of Object.values(change.changes.removed)) {
          console.log('handleChangeEvent - Removed - record', { record });
          /* if (record.typeName === 'shape') {
            logChangeEvent(`deleted shape (${record.type})`)
          } */
        }
      }
    }

    editor.on('change', handleChangeEvent)

    return () => {
      editor.off('change', handleChangeEvent)
    }
  }, [editor])


  useEffect(() => {
    let intervalId = null;
    let i = 0;

    if (isDBReady && editor) {
      intervalId = setInterval(() => {
        
        console.log('Autosave triggered', {i})
        triggerMessage();
        i++;
    
    }, autosaveInterval);

    }

    return () => clearInterval(intervalId);
 }, [isDBReady, editor])

  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
      }}
    >
      <ReactModal
        isOpen={openModal}
        style={{
          overlay: {
            backgroundColor: '#99999922'

          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }
        }}
      >

        <div>Les images et medias ne doivent pas dépasser 500k</div>
        <button onClick={() => { setOpenModal(false) }}>Fermer</button>

      </ReactModal>
      {isDBReady ?
        <>
          {/* <button onClick={triggerMessage}>send data to opair</button> */}
          {/*           {storeEvents.map((t, i) => (
						<div key={i}>{t}</div>
					))} */}
          <Tldraw
            persistenceKey={persistenceKeyTLDraw}
            overrides={uiOverrides(triggerMessage)}
            onMount={setAppToState}
            onUiEvent={handleEvent}
          />
        </>
        :
        <>loading...</>
      }
    </div>
  )
}